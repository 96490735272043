.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.central-div {
  margin-top: 15px;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 10px;
  border-radius: 15px;
  transition: all .3s;
  margin-right: 10;
}

.row-mb-5 {
  margin-bottom: 5px;
}

.button-mr-5 {
  margin-right: 5px;
}

.center {
  overflow: auto;
}

.center-pantalla-fija {
  margin-left: 120vh;
  width: 58vh;
  top: 20vh;
  position: absolute;
}

.center-detalles {
  position: relative;
  left: 0vh;
  top: 3vh;
  max-height: 480px;
}

.center-detalles .li {
  position: relative;
  margin-left: 10 px;
  margin-right: 10 px;
  left: 0;
  align-items: center;
  text-decoration: double;
  overflow: auto;
}

.cierre-caja-div>input {
  max-width: 100px;
}

.abono-div {
  border: 1px outset rgb(6, 42, 109);
  background-color: rgb(229, 229, 229);
  text-align: center;
  border-radius: 15px;
}

.abono-titulo {
  font-size: 14px;
  align-items: center;
}

.modal {
  --bs-modal-width: 115vh;
}

.resumen-caja-div {
  border: 1px outset rgb(6, 42, 109);
  background-color: rgb(229, 229, 229);
  text-align: center;
  border-radius: 15px;
  font-size: 23px;
  font-weight: bold;
}

.resumen-caja-div.error {
  color: red;
}

.resumen-caja-div.success {
  color: green;
}

.date-picker-input {
  cursor: pointer;
  text-align: center;
}

.chart-div {
  min-height: 300px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
}


.spinner-container {
  position: relative;
  z-index: 1001;
}

.error-message {
  color: red;
  font-weight: bold;
  margin-top: 10px;
}

.tabla-preciosList {
  overflow-x: auto;
  width: 100%;
  table-layout: fixed;
}

.tabla-preciosList Table {
  width: 100%;
  table-layout: fixed;
}


.venta-check {
  display: flex;
  justify-content: center;
  margin-left: 10px;
  margin-right: 70px;
}

.modal-ventas-abono {
  text-align: center;
  max-width: 900vh;
  max-height: 350vh;
  display: table;
  margin-left: 30vh;
  margin-top: 13.5vh;
  background-color: transparent;
  overflow-y: auto;
}

.detalle-entrada-li {
  display: flex;
  justify-content: space-between;
  width: 90%;
}